.print-list,
html .print-list:active {
    float: right;
    position: relative;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer;
    margin-right: 10px;
}

.print-list:hover {
    text-decoration: underline;
}

.print-container {
    display: none;
}

.print-section{
    margin: 20px;
}