.todo-list li .clone {
    display: none;
    position: absolute;
    top: 0;
    right: 40px;
    bottom: 0;
    width: 40px;
    height: 40px;
    margin: auto 0;
    font-size: 20px;
    color: rgba(151, 225, 80, 0.73);
    margin-bottom: 11px;
    transition: color 0.2s ease-out;
}

.todo-list li .clone:hover {
    color: rgba(83, 166, 1, 0.73);
}

.todo-list li .clone:after {
    content: 'C';
}

.todo-list li:hover .clone {
    display: block;
}
